export default class UI {
  static hide(element) {
    if (element) element.style.display = 'none';
  }

  static show(element, displayProp = 'block') {
    if (element && element.style.display !== displayProp) {
      element.style.display = displayProp;
    }
  }

  static createNotificationItem(notificationData) {
    const { data, read_at, created_at } = notificationData;
    const { subject, content_string } = data.payload;

    // notification container
    const notificationContainer = document.createElement('div');
    notificationContainer.setAttribute('class', 'dropdown-notification-item');

    // notification header
    const notificationHeader = document.createElement('div');
    notificationHeader.setAttribute('class', 'notifications-dropdown-head');
    if (!read_at) {
      notificationHeader.classList.add('unread');
    }
    const h6 = document.createElement('h6');
    h6.innerText = subject;
    notificationHeader.appendChild(h6);

    // notification body
    const notificationBody = document.createElement('div');
    notificationBody.setAttribute('class', 'notifications-dropdown-body');
    const p = document.createElement('p');
    p.innerText = content_string;
    const sm = document.createElement('small');
    sm.setAttribute('class', 'text-muted');
    sm.innerText = created_at;
    notificationBody.appendChild(p);
    notificationBody.appendChild(sm);

    notificationContainer.appendChild(notificationHeader);
    notificationContainer.appendChild(notificationBody);
    return notificationContainer;
  }

  static createNotificationsEmptyState() {
    const notificationsEmpty = document.createElement('div');
    notificationsEmpty.setAttribute('class', 'empty-state--notifications-dropdown');
    const imgBg = document.createElement('div');
    imgBg.setAttribute('class', 'image-bg');
    notificationsEmpty.appendChild(imgBg);
    return notificationsEmpty;
  }

  static createUserDropdownLink(linkData) {
    const link = document.createElement('a');
    if (linkData.onClick) {
      link.addEventListener('click', linkData.onClick);
    }
    link.href = linkData.href;
    link.setAttribute('class', 'dropdown-item');
    const icon = document.createElement('i');
    icon.setAttribute('class', linkData.iconClass);
    link.appendChild(icon);
    link.appendChild(document.createTextNode(linkData.text()));
    return link;
  }

  static createNavbarAuthLink(linkData) {
    const navItem = document.createElement('li');
    navItem.setAttribute('class', 'nav-item');
    navItem.id = linkData.id;
    const link = document.createElement('a');
    link.setAttribute('class', linkData.btnClasses);
    link.href = linkData.href;
    link.innerText = linkData.btnText;
    navItem.appendChild(link);
    return navItem;
  }

  static createSidebarLink(linkData) {
    const listItem = document.createElement('li');
    const link = document.createElement('a');
    link.href = typeof linkData.href === 'function' ? linkData.href() : linkData.href;
    const icon = document.createElement('i');
    icon.setAttribute('class', linkData.iconClass);
    const hoverDescription = document.createElement('span');
    hoverDescription.setAttribute('class', 'sidenav-link-description');
    hoverDescription.innerText = linkData.text();

    link.appendChild(icon);
    link.appendChild(hoverDescription);
    listItem.appendChild(link);

    if (linkData.id) {
      listItem.id = linkData.id;
    }

    return listItem;
  }
}
