import axios from 'axios';

class Api {
  constructor() {
    // create the axios instance
    this.api = axios.create({
      baseURL: `${document.location.origin}/api`,
    });

    // setup the axios response interceptor
    this.api.interceptors.response.use(
      response => response,
      async error => {
        const originalRequest = error.config;

        // intercept 401 responses when access token expires, make sure to avoid refresh-token route 401
        if (
          error.response.status === 401 &&
          error.response.config.url !== 'refresh-token' &&
          !originalRequest._retry
        ) {
          originalRequest._retry = true;

          try {
            const newAccessToken = await this.refreshToken();
            originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
          } catch (error) {
            console.error(error);
            if (this.logoutHandler) {
              this.logoutHandler();
            }
            return Promise.reject(error);
          }

          // retry executing the original request
          return await this.api(originalRequest);
        }

        // return the error
        return Promise.reject(error);
      }
    );

    // prepare for storing the logout handler function
    this.logoutHandler = null;

    this.changeLanguageAuth = this.changeLanguageAuth.bind(this);
    this.changeLanguageGuest = this.changeLanguageGuest.bind(this);
  }

  setHeader(header, value) {
    this.api.defaults.headers.common[header] = value;
  }

  setLogoutHandler(fn) {
    this.logoutHandler = fn;
  }

  async refreshToken() {
    try {
      const res = await this.api.post('refresh-token');
      const newAccessToken = res.data.access_token;
      if (!newAccessToken) throw new Error(res.data.message);
      this.setHeader('Authorization', `Bearer ${newAccessToken}`);
      localStorage.setItem('AuthorizationToken', `Bearer ${newAccessToken}`);

      return newAccessToken;
    } catch (error) {
      this.setHeader('Authorization', '');
      throw new Error(error);
    }
  }

  async logout() {
    await this.api.post('logout');
    this.setHeader('Authorization', '');
    return;
  }

  async getPlatformData() {
    const res = await this.api.get('app/init');
    return res.data.data;
  }

  async getCurrentUserState() {
    const res = await this.api.get('participant/profile/state');
    return res.data.data;
  }

  async getAppTranslations() {
    const { lang } = document.documentElement;
    // using axios.get instead of this.api.get because translation url is not prefixed with '/api'
    const url = `${document.location.origin}/storage/locales/app/${lang || 'en'}.json`;
    const res = await axios.get(url);
    return res.data;
  }

  async markNotificationAsRead(notificationId) {
    const res = await this.api.post(`notifications/mark-read/${notificationId}`);
    return res.data;
  }

  async getCurrentTimestamp() {
    const res = await this.api.get('date/cast-date-time');
    return res.data.data;
  }

  async addProgramToActivities(programId) {
    const res = await this.api.post(`activity/add-program/${programId}`);
    return res.data;
  }

  async removeProgramFromActivities(activityId) {
    const res = await this.api.delete(`activity/${activityId}`);
    return res.data;
  }

  async getProgramsAddedToActivities() {
    const res = await this.api.get('agenda/programs');
    return res.data;
  }

  async getSingleProgramActionRules(programId) {
    const res = await this.api.get(`agenda/program-action-rules/${programId}`);
    return res.data;
  }

  async addProgramToAgenda(programId) {
    const res = await this.api.post(`agenda/add-program/${programId}`);
    return res.data;
  }

  async removeProgramFromAgenda(programId) {
    const res = await this.api.delete(`agenda/program/${programId}`);
    return res.data;
  }

  async getMyChatRooms() {
    const res = await this.api.get('chats/rooms');
    return res.data;
  }

  /** change the active application language for guest users */
  async changeLanguageGuest(newLang) {
    const csrfMetaTag = document.querySelector('meta[name="csrf-token"]');

    // using axios instead of this.api because this route is not prefixed with '/app'
    const res = await axios.post(`${document.location.origin}/languages/switch`, {
      _token: csrfMetaTag?.attributes?.content?.textContent,
      locale: newLang,
    });

    return res.data;
  }

  /** change the active application language for logged in users */
  async changeLanguageAuth(newLang) {
    const res = await this.api.post('languages/switch', { locale: newLang });
    return res.data;
  }
}

const api = new Api();

export default api;
